var $ = require('../internals/export');

// `Date.now` method
// https://tc39.es/ecma262/#sec-date.now
$({
  target: 'Date',
  stat: true
}, {
  now: function now() {
    return new Date().getTime();
  }
});