'use strict';

var $ = require('../internals/export');
var $trimEnd = require('../internals/string-trim').end;
var forcedStringTrimMethod = require('../internals/string-trim-forced');
var FORCED = forcedStringTrimMethod('trimEnd');
var trimEnd = FORCED ? function trimEnd() {
  return $trimEnd(this);
  // eslint-disable-next-line es/no-string-prototype-trimstart-trimend -- safe
} : ''.trimEnd;

// `String.prototype.{ trimEnd, trimRight }` methods
// https://tc39.es/ecma262/#sec-string.prototype.trimend
// https://tc39.es/ecma262/#String.prototype.trimright
$({
  target: 'String',
  proto: true,
  forced: FORCED
}, {
  trimEnd: trimEnd,
  trimRight: trimEnd
});