var $ = require('../internals/export');
var toISOString = require('../internals/date-to-iso-string');

// `Date.prototype.toISOString` method
// https://tc39.es/ecma262/#sec-date.prototype.toisostring
// PhantomJS / old WebKit has a broken implementations
$({
  target: 'Date',
  proto: true,
  forced: Date.prototype.toISOString !== toISOString
}, {
  toISOString: toISOString
});