'use strict';

var anObject = require('../internals/an-object');
var toPrimitive = require('../internals/to-primitive');

// `Date.prototype[@@toPrimitive](hint)` method implementation
// https://tc39.es/ecma262/#sec-date.prototype-@@toprimitive
module.exports = function (hint) {
  if (hint !== 'string' && hint !== 'number' && hint !== 'default') {
    throw TypeError('Incorrect hint');
  }
  return toPrimitive(anObject(this), hint !== 'number');
};