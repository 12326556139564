var $ = require('../internals/export');
var fround = require('../internals/math-fround');

// `Math.fround` method
// https://tc39.es/ecma262/#sec-math.fround
$({
  target: 'Math',
  stat: true
}, {
  fround: fround
});