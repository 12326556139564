var $ = require('../internals/export');
var numberIsFinite = require('../internals/number-is-finite');

// `Number.isFinite` method
// https://tc39.es/ecma262/#sec-number.isfinite
$({
  target: 'Number',
  stat: true
}, {
  isFinite: numberIsFinite
});