var $ = require('../internals/export');
var isInteger = require('../internals/is-integer');

// `Number.isInteger` method
// https://tc39.es/ecma262/#sec-number.isinteger
$({
  target: 'Number',
  stat: true
}, {
  isInteger: isInteger
});